// External Dependencies
import React from 'react';

// Internal Dependencies
import Layout from '../components/layout';

// Component Definition
const CurrentEvent = () => (
  <Layout hideFooter hideNav>
    <div>
      <img
        alt="meetup-speaker"
        height="100%"
        src="https://res.cloudinary.com/reactjs-dallas/image/upload/v1723566253/2024-08-13--reactjs_dallas--vishnu_ramineni_udts8g.png"
        width="100%"
      />
    </div>
  </Layout>
);

export default CurrentEvent;
